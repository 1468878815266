@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
:root{
  --color-red: #DD1D21;
  --color-red-secondary: #E16D70;
  --color-red-light: #EB4335;

  --color-yellow: #FBCE07;

  --color-gray: #A6A6A6;
  --color-gray-secondary: #D9D9D9;

  --color-black: hsl(240, 3%, 13%);
  --color-dark: hsla(0, 0%, 0%, 0.2);
  --color-dark-secondary: #A6A6A6;

  --color-light: hsl(0 0% 100%);
  --color-light-secondary: hsl(0, 0%, 83%);

  --size-title: 1rem;
  --size-subtitle: 0.875rem;
  --size-text: 0.75rem;
  --space-base: 0.5rem;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 400;
  background-color: whitesmoke;
}

.content {
  padding: 15px 20px;
}


label {
  font-size: 13px;
}

a {
  text-decoration: none;
}

.iwmeYY {
  text-transform: uppercase;
  color: #949292;
  background: #fbfbfb;
  font-weight: bold;
}

.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}

.effect-1,
.effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.effect-1 {
  animation: rotate 1s ease infinite;
  border-left: 3px solid #cd1d33;
}
.effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
  border-left: 3px solid #cd1d33;
}
.effect-3 {
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 3px solid #cd1d33;
}

.loading .effects {
  transition: all 0.3s ease;
}

.fallback-logo {
  position: absolute;
  left: calc(50% - 65px);
  top: 40%;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

.list-group-item:hover {
  cursor: pointer;
}

.loader {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
